import React from 'react';

const PopupModal = ({ children, active }) => {

  return (
    <div className={`popup-modal ${active ? 'popup-modal--active' : ''}`}>
      <div className='popup-modal__inner'>
        <div className='popup-modal__content'>
          <button 
            type='button' 
            className='popup-modal__close'
            onClick={() => window._togglePopup()}
          />
          <div className='popup-modal__content-body'>
            { children }
          </div>
        </div>
      </div>
    </div>
  )
}

PopupModal.defaultProps = {
  children: null,
}

export default PopupModal