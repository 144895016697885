import React, { Component } from 'react'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'

import Link from '../utils/link'
import { LeftArrow, Mail, Logo, Search, Close } from './icons'
import PopupModal from './popup-modal'
import ActiveCampaignForm from './active-campaign-form'

const ProductTitle = 'Products'
const CompanyTitle = 'About'

class Header extends Component {

  lastDirection = 0

  constructor(props) {
    super(props)
    this.searchRef = React.createRef()

    const machines = process.env.GATSBY_WP_CATEGORY_SITE_SLUG === 'new-zealand' ? this.props.data.machinesNz.nodes : this.props.data.machinesAu.nodes
    const machineNodes = this.props.data.machineMenu.nodes.filter(item => !!machines?.find(machine => machine.uri === item.uri))
    machineNodes.sort((a, b) => a.order - b.order)

    this.state = {
      offCanvas: false,
      popup: false,

      // Header status
      scrolled: false,
      backgroundFill: false,

      // Menu status
      productsNav: false,
      companyNav: false,
      firstSub: null,
      secondSub: null,

      // Search status
      searchActive: false,
      searchInput: '',
      machineMenu: { nodes: machineNodes },
    }
  }

  componentDidMount() {
    window._toggleOffCanvas = this.toggleOffCanvas
    window._togglePopup = this.togglePopup

    window.addEventListener('scroll', this.throttleScroll)
  }

  throttleScroll = () => {
    if (!this.ticking) {
      requestAnimationFrame(this.handleScroll)
    }
    this.ticking = true
  }

  handleScroll = () => {
    this.setState({
      scrolled: this.lastDirection < window.scrollY && window.scrollY > 200,
      backgroundFill: window.scrollY > 0
    })
    this.lastDirection = window.scrollY
    this.ticking = false
  }

  hideOffCanvas = () => {
    this.setState({ offCanvas: false, searchActive: false, productsNav: false, companyNav: false })
  }

  toggleOffCanvas = () => {
    document.body.classList.toggle('no-scroll', !this.state.offCanvas)
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  togglePopup = () => {
    document.body.classList.toggle('no-scroll', !this.state.popup)
    this.setState({ popup: !this.state.popup, searchActive: false })
  }

  onSubmit = (event) => {
    event.preventDefault()
  }

  resetHeader = () => {
    this.setState({
      productsNav: false,
      companyNav: false,
      firstSub: null,
      secondSub: null,
      offCanvas: false,
    })
  }

  render() {
    let { 
      offCanvas, scrolled, backgroundFill, popup, machineMenu,
      companyNav, productsNav, firstSub, secondSub, searchActive
    } = this.state

    const { headerMenu, companyMenu, wp } = this.props.data

    const props = {
      onClick: this.hideOffCanvas,
      activeClassName: 'active'
    }

    const deactivateNavProps = {
      activeClassName: 'active',
      onMouseEnter: (e) => {
        const title = e.target.innerText
        this.setState({ 
          productsNav: title === ProductTitle, // || (productsNav && title !== CompanyTitle),
          companyNav: title === CompanyTitle, // || (companyNav && title !== ProductTitle),
        })
      },
      onClick: (e) => {
        const title = e.target.innerText
        if (window.innerWidth < 900) {
          if (title === ProductTitle) {
            e.preventDefault()
            return this.setState({ productsNav: true })
          }
          if (title === CompanyTitle) {
            e.preventDefault()
            return this.setState({ companyNav: true })
          }
        }
        this.hideOffCanvas()
      },
    }

    let headerClass = 'header'
    if (scrolled && (!productsNav || !companyNav) && !popup) headerClass += ' header--scrolled'
    if (backgroundFill || productsNav || companyNav) headerClass += ' header--background-fill'
    if (searchActive) headerClass += ' header--search'
    if (offCanvas) headerClass += ' header--off-canvas'

    const firstSubList = machineMenu?.nodes?.filter(el => el.parentId === firstSub?.id)
    const secondSubList = machineMenu?.nodes?.filter(el => el.parentId === secondSub?.id)

    return (
      <>
        <header className={headerClass} onMouseLeave={() => this.setState({ productsNav: false, companyNav: false })}>

          { wp.siteOptions.acf.announcement &&
            <div className='header__announcement'>
              <p>{parse(wp.siteOptions.acf.announcement)}</p>
            </div>
          }

          <div className='header__inner'>
            <Link to='/' title='Headland' className='header__logo' {...props}>
              <Logo />
            </Link>

            <nav>
              {headerMenu?.menuItems?.nodes?.map((el, i) => (
                <Link key={i} to={el.uri} title={el.label} {...deactivateNavProps}>
                  <span>{el.label}</span>
                </Link>
              ))}
            </nav>

            <div className='header__cta'>

              <div className='header__cta-links'>
                <Link to={`/machines-in-stock/`} className='header__cta-links-text'>
                  Machines In Stock
                </Link>
                <button 
                  onClick={this.togglePopup}
                  type='button' 
                  className='header__cta-links-icon'>
                  <Mail color="#00D991" />
                </button>
              </div>

              <div className='header__search'>
                <button 
                  type='button' 
                  onClick={() => {
                  if (window.innerWidth < 900) {
                    return navigate('/search/')
                  }
                  if (!searchActive) {
                    setTimeout(() => this.searchRef.current.focus(), 300)
                  }
                  this.setState({ searchActive: !searchActive })
                }}>
                  <Search />
                </button>
                <form action='/search/' onSubmit={() => console.log('Submit search')}>
                  <input 
                    ref={this.searchRef}
                    type='text' 
                    name='q' 
                    placeholder='What are you looking for…'
                    required
                  />
                </form>
                <button 
                  type='button' 
                  className='btn-close'
                  onClick={() => this.setState({ searchActive: false })}>
                  <Close />
                </button>
              </div>

              <button type='button' className={`header__off-canvas-toggle ${offCanvas ? 'active' : ''}`} onClick={this.toggleOffCanvas}>
                <svg viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <line x1="0" y1="14" x2="48" y2="14" strokeWidth="5" />
                    <line x1="0" y1="34" x2="48" y2="34" strokeWidth="5" />
                  </g>
                  <g>
                    <line x1="0" y1="24" x2="48" y2="24" strokeWidth="5" />
                    <line x1="0" y1="24" x2="48" y2="24" strokeWidth="5" />
                  </g>
                </svg>
              </button>

            </div>
          </div>

          <div className={`header__dropdown ${companyNav ? 'active' : ''}`}>
            <div className='header__dropdown-background' />
            <div className='header__dropdown-inner'>
              <div className='header__dropdown-content'>
                <h5>Overview About</h5>
                <ul>
                  {companyMenu?.nodes?.filter(el => el.parentId === null).map((el, i) => (
                    <li key={i}>
                      <Link 
                        to={el.uri} title={el.label} 
                        onClick={this.resetHeader}
                      >
                        <span>{el.label}</span>
                        { companyMenu?.nodes?.filter(a => a.parentId === el?.id)?.length > 0 && <LeftArrow /> }
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='header__dropdown-content'></div>
            </div>
          </div>

          <div className={`header__dropdown ${productsNav ? 'active' : ''}`}>
            <div className='header__dropdown-background' />
            <div className='header__dropdown-inner'>
              <div className='header__dropdown-content'>
                <h5>Overview Products</h5>
                <ul>
                  {machineMenu?.nodes?.filter(el => el.parentId === null).map((el, i) => (
                    <li key={i}>
                      <Link 
                        to={el.uri} title={el.label} 
                        onClick={this.resetHeader}
                      >
                        <span
                          onMouseEnter={() => this.setState({ firstSub: el, secondSub: null })}
                        >{el.label}</span>
                        { machineMenu?.nodes?.filter(a => a.parentId === el?.id)?.length > 0 && <LeftArrow /> }
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={`header__dropdown-content ${firstSub && firstSubList?.length ? 'active' : 'inactive'}`}>
                <h5>
                  <Link 
                    to={firstSub?.uri}
                    onClick={this.resetHeader}
                  >
                    { firstSub && `Overview ${ firstSub?.label }` }
                  </Link>
                </h5>
                <ul>
                  {firstSubList.map((el, i) => (
                    <li key={i}>
                      <Link 
                        to={el.uri} title={el.label}
                        onClick={this.resetHeader}
                      >
                        <span
                          onMouseEnter={() => this.setState({ secondSub: el })}
                        >{el.label}</span>
                        { machineMenu?.nodes?.filter(a => a.parentId === el?.id)?.length > 0 && <LeftArrow /> }
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={`header__dropdown-content ${secondSub && secondSubList?.length ? 'active' : 'inactive'}`}>
                <h5>
                  <Link 
                    to={secondSub?.uri}
                    onClick={this.resetHeader}
                  >
                    { secondSub && `Overview ${ secondSub?.label }` }
                  </Link>
                </h5>
                <ul 
                  style={{ gridTemplateRows: `repeat(${secondSubList?.length >= 10 ? Math.ceil(secondSubList?.length / 2) : 13}, 1fr)`}}
                  className={`long-list ${secondSubList?.length >= 10 ? 'extra-long' : ''}`}>
                  {secondSubList.map((el, i) => (
                    <li key={i}>
                      <Link 
                        to={el.uri} title={el.label}
                        onClick={this.resetHeader}
                      >
                        <span>{el.label}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

        </header>

        <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
          <div className='off-canvas__inner'>
            <div>
              <nav className={productsNav ? '' : 'active'}>
                {headerMenu?.menuItems?.nodes?.map((el, i) => (
                  <Link key={i} to={el.uri} title={el.label} {...deactivateNavProps}>
                    <span>{el.label}</span>
                    <LeftArrow />
                  </Link>
                ))}
              </nav>

              <nav className={companyNav ? 'active' : ''}>
                <button type='button' onClick={() => this.setState({ companyNav: false })} className='back-btn'>
                  <LeftArrow />
                  <span>Back</span>
                </button>
                <Link to='/company/' className='back-btn' onClick={this.resetHeader}>
                  <span>Overview Company</span>
                </Link>
                {companyMenu?.nodes?.filter(el => el.parentId === null).map((el, i) => (
                  <Link to={el.uri} key={i}
                    onClick={(event) => {
                      this.resetHeader()
                    }}
                  >
                    <span>{el.label}</span>
                    { companyMenu?.nodes?.filter(a => a.parentId === el.id).length > 0 && <LeftArrow /> }
                  </Link>
                ))}
              </nav>

              <nav className={productsNav && !firstSub ? 'active' : ''}>
                <button type='button' onClick={() => this.setState({ productsNav: false })} className='back-btn'>
                  <LeftArrow />
                  <span>Back</span>
                </button>
                <Link to='/products/' className='back-btn' onClick={this.resetHeader}>
                  <span>Overview Products</span>
                </Link>
                {machineMenu?.nodes?.filter(el => el.parentId === null).map((el, i) => (
                  <Link to={el.uri} key={i}
                    onClick={(event) => {
                      if (machineMenu?.nodes?.filter(a => a.parentId === el.id).length > 0) {
                        event.preventDefault()
                        this.setState({ firstSub: el, secondSub: null })
                      } else {
                        this.resetHeader()
                      }
                    }}
                  >
                    <span>{el.label}</span>
                    { machineMenu?.nodes?.filter(a => a.parentId === el.id).length > 0 && <LeftArrow /> }
                  </Link>
                ))}
              </nav>

              <nav className={firstSub && !secondSub ? 'active' : ''}>
                <button type='button' onClick={() => this.setState({ firstSub: null })} className='back-btn'>
                  <LeftArrow />
                  <span>Back</span>
                </button>
                <Link 
                  to={firstSub?.uri} className='back-btn'
                  onClick={this.resetHeader}
                >
                  { `Overview ${ firstSub?.label }` }
                </Link>
                {firstSubList.map((el, i) => (
                  <Link to={el.uri} key={i}
                    onClick={(event) => {
                      if (machineMenu?.nodes?.filter(a => a.parentId === el.id).length > 0) {
                        event.preventDefault()
                        this.setState({ secondSub: el })
                      } else {
                        this.resetHeader()
                      }
                    }}
                  >
                    <span>{el.label}</span>
                    { machineMenu?.nodes?.filter(a => a.parentId === el.id).length > 0 && <LeftArrow /> }
                  </Link>
                ))}
              </nav>

              <nav className={secondSub ? 'active' : ''}>
                <button type='button' onClick={() => this.setState({ secondSub: null })} className='back-btn'>
                  <LeftArrow />
                  <span>Back</span>
                </button>
                <Link 
                  to={secondSub?.uri} className='back-btn'
                  onClick={this.resetHeader}
                >
                  { `Overview ${ secondSub?.label }` }
                </Link>
                {secondSubList.map((el, i) => (
                  <Link 
                    to={el.uri} title={el.label} key={i}
                    //onMouseEnter={() => this.setState({ firstSub: el, secondSub: null })}
                    onClick={this.resetHeader}
                  >
                    <span>{el.label}</span>
                    { machineMenu?.nodes?.filter(a => a.parentId === el.id).length > 0 && <LeftArrow /> }
                  </Link>
                ))}
              </nav>

            </div>
          </div>
        </div>

        <PopupModal active={popup}>
          { wp.siteOptions.acf.contactPopupText && <div>{ parse(wp.siteOptions.acf.contactPopupText) }</div> }
          { popup && <ActiveCampaignForm formId={11} /> }
        </PopupModal>

      </>
    )
  }
}

export default Header
