import React, { useState, useEffect } from 'react'
import InnerHTML from 'dangerously-set-html-content'

const ActiveCampaignForm = ({ formId, height }) => {

  const [form, setForm] = useState(null)

  useEffect(() => {
    fetch(`/api/active-campaign?formId=${formId}`, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setForm(data.body)
      })
  }, [formId])

  return (
    <div className='active-campaign-form'>
      <div className='active-campaign-form__frame'>
        { form && <InnerHTML html={form} /> }
      </div>
    </div>
  )
}

ActiveCampaignForm.defaultProps = {
  formId: '',
  height: 'auto',
}

export default ActiveCampaignForm